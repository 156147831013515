import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const item1 = document.querySelector('.intro-category-item.first');
const item2 = document.querySelector('.intro-category-item.second');

const introBg1 = document.querySelector('.intro-bg.first');
const introBg2 = document.querySelector('.intro-bg.second');

const headerMobileBtn = document.querySelector('.header-menu-toggler');
const headerMobileMenu = document.querySelector('.header-nav');

const dropdownToggler = document.querySelector('.lang-switcher-toggle');
const dropdownList = document.querySelector('.lang-switcher-list');

// lang toggle
if (dropdownToggler) {
    dropdownToggler.addEventListener('click', (e) => {
        dropdownList.classList.toggle('show')
    });
}


// intro hover
if (item1) {
    item1.addEventListener('mouseover', () => {
        introBg1.classList.add('active');
    });

    item1.addEventListener('mouseout', () => {
        introBg1.classList.remove('active');
    });
}

if (item2) {
    item2.addEventListener('mouseover', () => {
        introBg2.classList.add('active');
    });

    item2.addEventListener('mouseout', () => {
        introBg2.classList.remove('active');
    });
}

// header mobile
headerMobileBtn.addEventListener('click', function () {
    headerMobileMenu.classList.toggle('active');
    document.body.classList.toggle('fixed');
    this.classList.toggle('active');
});

const explorersSlider = new Swiper('.explorers-slider', {
    direction: 'horizontal',
    loop: true,
    pagination: {
        el: '.explorers-slider-pagination',
        clickable: true,
        bulletClass: 'explorers-slider-bullet',
        bulletActiveClass: 'active'
    },
    navigation: {
        nextEl: '.explorers-slider-arrow.next',
        prevEl: '.explorers-slider-arrow.prev',
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    autoplay: {
        delay: 2000,
        disableOnInteraction: false
    },
});
